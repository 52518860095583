<template>
  <v-card>
    <v-card-title class="px-2">
      <span>
        {{ $t('UserOnline') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        class="d-none d-md-block"
      >
        {{ $t('excel') }}
      </v-btn>
      <v-btn
        color="primary"
        class="d-block d-md-none"
        fab
        icon
        outlined
      >
        <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-2">
      <v-col
        cols="12"
        md="6"
        class="py-0"
        lg="4"
      >
        <v-text-field
          v-model="searchtext"
          :label="$t('search')"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="py-0"
        lg="4"
      >
        <v-select
          v-model="online_status_id"
          :items="statusList"
          :label="$t('status')"
          :item-text="$i18n.locale"
          outlined
          dense
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.online_id`]="{ index }">
        {{ segmentId + index + 1 }}
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <Pagination
      :page-data="options.page"
      :page-count="totalPage"
      :segment-id="+segmentId "
      :count-list="dataTableList.length"
      :total="+totalDataTableList"
      @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
      @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
    />
  </v-card>
</template>

<script>
import { mdiFileExcelOutline } from '@mdi/js'
import useUserOnline from './useUserOnline'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
  },

  setup() {
    return {

      ...useUserOnline(),
      icons: {
        mdiFileExcelOutline,
      },
    }
  },

}
</script>
