import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const userOnlineList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'online',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get user online list err : ', err)

      return {}
    })

  return response
}
export default { userOnlineList }
