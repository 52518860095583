import basicStatus from '@/@fake-db/data/basicStatus.json'
import userOnline from '@/api/WebsiteManageMent/userOnline'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

export default function useUserOnline() {
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const online_status_id = ref('')
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'online_id',
      width: '50',
    },
    {
      text: i18n.t('firstname_lastname'),
      value: 'online_fullname',
    },
    { text: i18n.t('id_card'), value: 'online_idcard' },
    { text: i18n.t('gender'), value: 'online_gender' },
    { text: i18n.t('tel'), value: 'online_tel' },
    {
      text: i18n.t('email'),
      value: 'online_email',
      width: 120,
    },
    { text: i18n.t('birthday'), value: 'online_birthdate' },
    { text: i18n.t('status'), value: 'online_status_name', align: 'center' },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const loading = ref(false)
  const segmentId = ref(0)
  const totalPage = ref(0)

  const { userOnlineList } = userOnline

  const fetchDataTable = () => {
    loading.value = true
    userOnlineList({
      searchtext: searchtext.value,
      online_status_id: online_status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, online_status_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    statusList,
    online_status_id,
    options,
    footer,
    dataTableList,
    totalDataTableList,
    loading,
    totalPage,
    columns,
    segmentId,
    fetchDataTable,
  }
}
